import { Div } from "./Elements";
//import NanoClamp from 'nanoclamp';

export default function TagsAndDate(fields, dateContent, noclamp) {
  if (!fields) {
    return null;
  }
  var numLinesToClamp = noclamp ? 0 : 1;
  var tagsContent = fields.tags && fields.tags.length ? `(${fields.tags.join(", ")})` : null;
  return (
    <>
        <Div size="16/13"
          className="tags upper"
          style={{ marginRight: tagsContent?'4px':'0', minHeight: tagsContent?'auto':'1em' }}
          lineclamp={numLinesToClamp} overflowchars='...)'
        >
          {tagsContent}
         {/*} {'('+fields.tags.join(", ")+')'} {*/}
        </Div>
      {dateContent === null ? <></> :
        <Div size="16/13" className="upper" lineclamp={numLinesToClamp}>
          {dateContent || fields.formattedTime}
        </Div>}
    </>
  );
};