
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
  Outlet,
  useLocation
} from 'react-router-dom';
import _ from 'lodash';
import BarDesktop from './BarDesktop';
import BarMobile from './BarMobile'
import Footer from './Footer';
import {
  Desktop,
  Mobile,
  DesktopClass
} from './modules/useResponsive';
import ScrollToTop from './modules/ScrollToTop'
//TEMP
import StyleGuide from './StyleGuide'

// PAGES
import Landing from './Landing';
import Listen from './routes/Listen';
import ListenShow from './routes/ListenShow';
import ListenShowEpisode from './routes/ListenShowEpisode';
import ListenSchedule from './routes/ListenSchedule';
import ListenCharts from './routes/ListenCharts';
import Read from './routes/Read';
import ReadColumn from './routes/ReadColumn';
//import ReadIssue from './routes/ReadIssue';
import ReadIssueArticle from './routes/ReadIssueArticle';
import SearchResults from './routes/SearchResults';
import PlayerBar from './components/PlayerBar';

import Events from './routes/Events';
import Info from './routes/Info';
import GetInvolved from './routes/GetInvolved';
import Shop from './routes/Shop';
import Services from './routes/Services';
import Support from './routes/Support';
import Contact from './routes/Contact';
import Data from './modules/Data';
import WordpressApi from './modules/WordpressApi';
import StaticPage from './components/StaticPage';

import Genres from './routes/Genres';
import ReadPastIssues from './ReadPastIssues';

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <></>;
}

function Page() {

  const location = useLocation();
  var pageIsDark = false;
  var darkPages = '/schedule'.split(' ');

  if (_.includes(location.pathname, darkPages)) {
    console.log("override dark page")
    pageIsDark = true;
  }

  var defaultOverflow = false;
  var defaultOverflowPages = '/listen/'.split(' ');
  if (_.includes(location.pathname, defaultOverflowPages)) {
    console.log("override default overflow")
    defaultOverflow = true;
  }

  return (
    <>
      <ScrollToTop />
      <Desktop>

        <BarDesktop />
      </Desktop>
      <Mobile>
        <BarMobile />
      </Mobile>
      <div className={"PageContent " + (Desktop() ? "Desktop" : "")}
        style={{
          background: pageIsDark ? 'black' : 'white',
          overflowX: defaultOverflow ? 'visible' : 'hidden'
        }}>
        <Outlet />
        <div className={DesktopClass('white-line')} ><div /></div>
      </div>
      <Footer />
      <PlayerBar />
    </>
  )
}
var staticPageSlugs = {
  contact: {
    slug: 'contact'
  },
  policy: {
    slug: 'policies-and-resources'
  }
}

function staticPageRoutes() {
  return _.map(staticPageSlugs, ({ slug }, path) => {
    return {
      path,
      element: <StaticPage path={path} />,
      loader: () => {
        return WordpressApi.page(slug)
          .then(result => {
            console.log('result', result);
            return _.get(result, 'content.rendered');
          });
      }
    }
  });
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Page />,
    errorElement: <><BarDesktop /><br /><br /><br /><br /><h4>Sorry, something went wrong :(</h4><ErrorBoundary /></>,
    /*loader: () => {
      return Data.shows.nowPlaying()
        .then(nowPlaying => {
          return { nowPlaying };
        })
    },*/
    children: [
      ...staticPageRoutes(),
      {
        index: true,
        element: <Landing />
      },
      {
        path: "listen",
        element: <Listen />,
        loader: () => {
          return Promise.all([Data.shows.getOnAirShows(), Data.topAlbums()]);
        }
      },
      {
        path: "listen/:showIdentifier",
        element: <ListenShow />,
        loader: ({ params }) => {
          console.log("show page, load show ", params.showIdentifier)
          return Promise.all([
            Data.shows.get(params.showIdentifier)
          ])
            .then(([show]) => {
              console.log('show', show);
              if (!show) {
                return {
                  errors: [
                    "Show not found"
                  ]
                }
              }
              return Data.schedule(show)
                .then(schedule => {
                  return {
                    show, schedule
                  };
                });
            });
        }
      },
      {
        path: "listen/:showSlug/:episodeId",
        element: <ListenShowEpisode />,
        loader: ({ params }) => {
          console.log("params in episode is ", params);
          console.log("episode page, load episode ", params.episodeId)
          return Promise.all([
            Data.episodes.get(params.episodeId)
          ])
            .then(([episode]) => {
              return { show: episode.show, episode };
            });
        }
      },
      {
        path: "genres/:genreSlug?",
        element: <Genres />,
        loader: ({ params }) => {
          return Data.genres(0, 1000)
            .then(genres => {
              return { 
                allGenres: genres, 
                currentGenreSlug: params.genreSlug 
              };
            });
        },
      },

      {
        path: "schedule",
        element: <ListenSchedule />,
        loader: ({ params }) => {
          return Data.schedule()
            .then(s => {
              return { schedule: s };
            });// Promise.resolve({ schedule: scheduleFixture });
        }
      },
      {
        path: "charts",
        element: <ListenCharts />,
        loader: ({ params }) => {
          return Data.topAlbums()
        }
      },
      {
        path: "info",
        element: <Info />
      },
      {
        path: "contact",
        element: <Contact />
      },
      {
        path: "events",
        element: <Events />,
        loader: () => {
          return Promise.all([
            Data.events.upcoming(),
            Data.events.past(),
            Data.announcements.list()])
            .then(([upcoming, past, announcements]) => { return { upcoming, past, announcements } })
        }
      },
      {
        path: "get-involved",
        element: <GetInvolved />
      },
      {
        path: "read",
        element: <Read />,
        loader: () => {
          return Promise.all([
            Data.issues.latest(),
            Data.columns.list()
          ]).then(([latestIssue, columns]) => {
            return { latestIssue, columns };
          })
        }
      },
      {
        path: "read/column/:columnSlug",
        element: <ReadColumn />,
        loader: ({ params }) => {
          return Promise.all([
            Data.columns.list()
          ])
            .then(([columns]) => {
              var selectedColumn = columns.find(col => col.slug === params.columnSlug);
              return { columns, selectedColumn };
            });
        }
      },
      {
        path: "read/column",
        element: <ReadColumn />,
        loader: ({ params }) => {
          return Promise.all([
            Data.columns.list()
          ])
            .then(([columns]) => {
              var selectedColumn = null;
              return { columns, selectedColumn };
            });
        }
      },
      {
        path: "read/:issueId/:articleSlug",
        element: <ReadIssueArticle />,
        loader: ({ params }) => {
          return Promise.all([
            Data.articles.get(params.articleSlug)
          ]).then(([article]) => {
            return { article };
          })
        }
      },
      {
        path: "read/issue/:issueId",
        element: <div> Issue page (?)</div>
      },
      {
        path: "read/issues",
        element: <ReadPastIssues />
      },
      {
        path: "search",
        element: <SearchResults />
      },
      {
        path: "services",
        element: <Services />
      },
      {
        path: "shop",
        element: <Shop />
      },
      {
        path: "support",
        element: <Support />
      },
      {
        path: "*",
        element: (
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!&nbsp;!&nbsp;</p>
          </main>
        )
      },
      {
        path: "styles",
        element: <StyleGuide />,
        loader: () => {
          return Promise.all([Data.shows.getOnAirShows(), Data.articles.latest(), Data.episodes.latest()])
            .then(([shows, articles, episodes]) => {
              return { shows, articles, episodes };
            });
        }
      }
    ]
  }
]);

export default function Router() {

  return (

    <RouterProvider router={router}>
    </RouterProvider>

  );
}