import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";

function BarCommon({ ToggleExpand }) {
  const [searching, setSearching] = useState(false);
  var navigate = useNavigate();
  var searchInput = React.createRef();
  var [urlSearchParams] = useSearchParams();

  var location = useLocation();

  const [searchTerm, setSearchTerm] = useState(urlSearchParams.get("q") || "")

  useEffect(() => {
    ToggleExpand(null, true);
    if (_.includes(location.pathname, '/search')) {
      setSearching(true);
      setSearchTerm(urlSearchParams.get("q") || "");
    } else {
      setSearching(false);
    }
  }, [location, urlSearchParams])

  function DisappearsWhenSearching({ children }) {
    return searching ? <></> : (
      <>{children}</>
    );
  }

  function executeSearch(event) {
    event.preventDefault();
    var formElement = event.target;

    var data = new FormData(formElement);
    var context = _.includes(location.pathname, '/listen') ? "shows" : "articles";
    navigate(`search?q=${data.get('query')}&active=${context}`);
  }

  function SearchInput() {
    if (searching) {
      return (
        <div>
          <form onSubmit={executeSearch}>
            <input
              type="text"
              name="query"
              ref={searchInput}
              autoFocus
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value || "");
              }}></input>

          </form>
        </div>
      );
    }
  }


  return {
    searching,
    setSearching,
    SearchInput,
    DisappearsWhenSearching,
    executeSearch,
    location,
    urlSearchParams
  }

}

export default BarCommon;