import { useState, useEffect } from "react";
import _ from "lodash";

import ColumnLink from "../components/ColumnLink";
import { Div, Header } from "../components/Elements";
import Grid from "../components/Grid";
import ShowCard from "../components/ShowCard";

import Data from "../modules/Data";
import RouteFor from "../modules/RouteFor";
import { Desktop } from "../modules/useResponsive";
import ScrollBehaviour from "../modules/ScrollBehaviour";

const { useLoaderData } = require("react-router-dom");

function Genres() {
  var { currentGenreSlug, allGenres } = useLoaderData();
  
  const [loading, setLoading] = useState(false);
  const [size] = useState(11);
  const [genreShowResults, setGenreShowResults] = useState([]);
  const [numGroups, setNumGroups] = useState(1);
  const [genres, setGenres] = useState(allGenres.slice(0, size));
  
  useEffect(() => {
    console.log("currentGenreSlug un use effect", currentGenreSlug);
    if (currentGenreSlug) {
      setLoading(true);
      setGenreShowResults([]);
      Data.shows.showsWithGenre(currentGenreSlug).then((data) => {
        setGenreShowResults(data);
        setLoading(false);
      }).catch((e)=>{
        console.error(e);
        setLoading(false);
      });
    }
  }, [currentGenreSlug]);
  
  useEffect(()=>{
    if (genreShowResults.length > 0){

      ScrollBehaviour.scrollToId("shows-"+currentGenreSlug);
    }

  },[genreShowResults])

  var isDesktop = Desktop();

  function loadMoreGenres() {
    var newNumGroups = numGroups + 1;
    setNumGroups(newNumGroups);
    var n = size * newNumGroups;
    setGenres(allGenres.slice(0, n));
  }

  var linkStyles = {
    d: {
      padding: "0",
      //      marginBottom: "24px",
      lineHeight: "43px"
    },
    m: {
      padding: "0",
      lineHeight: "30px",
      width: "auto",
      fontSize: "20px"
    }
  };


  var showCardStyle = isDesktop
    ? {
      width: "calc(calc(calc(100vw - 100px) * 0.25) - 16px)",
      aspectRatio: 1
    }
    : {
      aspectRatio: 1,
      width: "83.3%",
      height: "auto"
    };

  return (
    <Div style={{ marginTop: '21px' }}>
      <Header
        size="16"
        className={"top-border bold"}
        style={{
          lineHeight: "24px",
          textTransform: "uppercase"
        }}
      >
        Genres
      </Header>
      <Div
        flex="row"
        style={{
          flexWrap: "wrap",
          justifyContent: "space-between",
          columnGap: "18px"
        }}
        d={{
          marginTop: "60px",
          rowGap: "24px"
        }}
        m={{
          marginTop: "24px",
          rowGap: "12px"
        }}
      >
        {genres.map((genre) => (
          <ColumnLink
            to={
              currentGenreSlug && currentGenreSlug === genre.slug
                ? "../genres"
                : RouteFor({ genre })
            }
            key={genre.slug}
            name={genre.name}
            d={linkStyles.d}
            m={linkStyles.m}
            style={
              currentGenreSlug
                ? {
                  opacity: genre.slug === currentGenreSlug ? "1" : "0.3"
                }
                : {
                  opacity: "1"
                }
            }
          ></ColumnLink>
        ))}

        {_.size(genres) < _.size(allGenres) ? (
          <ColumnLink
            onClick={() => loadMoreGenres()}
            d={linkStyles.d}
            m={linkStyles.m}
            style={{
              opacity: currentGenreSlug ? "0.3" : "1",
              textAlign: "end",
              flexGrow: 1
            }}
          >
            MORE+
          </ColumnLink>
        ) : null}
      </Div>
      {loading? <Div flex="row center" style={{margin:'32px'}}> loading... </Div>:""}
      {currentGenreSlug && genreShowResults.length ? (
        <Grid
          id={"shows-"+currentGenreSlug}
          d={{
            marginTop: "36px",
            marginBottom: "137px"
          }}
          m={{
            marginBottom:"103px"
          }}
          pageSize={12}
          childElementFn={(show) => (
            <ShowCard 
              show={show} 
              key={show.id} 
              thumbnail 
              titleSize="20" 
              style={showCardStyle} 
              m={{
                width:"100%"
              }}
              />
          )}
          initialData={genreShowResults}
          buttonStyle={{
            marginTop: isDesktop ? "32px" : "36px",
            marginBottom: isDesktop ? "75px" : "36px",
            display: "none"
          }}

        ></Grid>
      ) : null}
    </Div>
  );
}

export default Genres;
