
import NanoClamp from "nanoclamp";
import ParseStyleProps from "../modules/ParseStyleProps";
import { Link } from "react-router-dom";
// import { Desktop } from "../modules/useResponsive";

// FLEX SHORTHAND
// flexDirection justifyContent alignItems

function Div({ id, size, d, m, lineclamp, overflowchars, children, style, className, flex, onClick, html }) {

  var props = ParseStyleProps({ size, d, m, style, className, flex });

  if (lineclamp && lineclamp !== 0 && lineclamp !== '0') {
    var ellipsis = overflowchars ? overflowchars : '...';
    children = <NanoClamp text={children} lines={lineclamp} ellipsis={ellipsis} accessibility={true} />
  }
  if (html) {
    return (<div id={id} style={props.style} className={props.classes} onClick={onClick} dangerouslySetInnerHTML={{ __html: html }} >
    </div>)
  } else {
    return (<div id={id} style={props.style} className={props.classes} onClick={onClick} >
      {children}
    </div>)
  }

}

function Header({ id, size, d, m, children, style, className, flex, html }) {

  var props = ParseStyleProps({ size, d, m, style, className, flex });

  return html ? (<h2 id={id} style={props.style} className={props.classes} dangerouslySetInnerHTML={{ __html: html }} >

  </h2>) : (<h2 id={id} style={props.style} className={props.classes} >
    {children}
  </h2>)
}


function StyledLink({ id, size, d, m, children, style, className, to, flex, onClick, html, target, rel }) {

  var props = ParseStyleProps({ size, d, m, style, className, flex });

  if (to === '#') {
    return html ? <span id={id} style={props.style} className={props.classes} onClick={onClick} dangerouslySetInnerHTML={{ __html: html }} />
      : <span id={id} style={props.style} className={props.classes} onClick={onClick} >
        {children}
      </span>
  } else {
    return html ? <Link id={id} style={props.style} className={props.classes} to={to} onClick={onClick} dangerouslySetInnerHTML={{ __html: html }} />
      : <Link id={id} style={props.style} className={props.classes} to={to} onClick={onClick} target={target} rel={rel}>
        {children}
      </Link>
  }
}

function Button({ id, d, m, children, style, className, flex, to, onClick }) {

  var props = ParseStyleProps({ size: '13/10', d, m, style, className, flex });

  var button = <button id={id} style={props.style} className={props.classes} onClick={onClick}>
    {children}
  </button>;

  if (to) {
    return <Link id={id} to={to}>{button}</Link>
  } else {
    return button;
  }
}

function Img({ id, d, m, style, className, onClick, src, alt = "" }) {

  var props = ParseStyleProps({ d, m, style, className });
  return (
    <img
      id={id}
      style={props.style}
      className={props.classes}
      onClick={onClick}
      src={src}
      alt={alt} />
  )

}


export { Div, Header, StyledLink, Button, Img } 