import _ from "lodash";
import { DateTime } from "luxon";
import { Div, Header } from "../components/Elements";
import { Desktop, Mobile } from "../modules/useResponsive";
import { useLoaderData } from "react-router-dom";
import HorizontalScroller from "../components/HorizontalScroller";
import {CustomDurationFormat} from "../modules/CustomDateTimeFormats";

function EventsHeader({ children, d, m }) {
  return (
    <Header
      style={{
        fontWeight: "bold"
      }}
      d={{
        fontSize: "48px",
        lineHeight: "72px",
        ...d
      }}
      m={{
        fontSize: "20px",
        lineHeight: "30px",
        ...m
      }}
    >
      {children}
    </Header>
  );
}

export default function Events() {
  var { upcoming, past, announcements } = useLoaderData();

  var pastMonths = _.toPairs(
    _.groupBy(past, (evt) => {
      var monthFirstDay = DateTime.fromISO(evt.start)
        .set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toISO();
      return monthFirstDay;
    })
  );
  pastMonths = _.reverse(_.sortBy(pastMonths, _.first));
  //  console.log('past', pastMonths)

  function CountDownLabel({ event, style }) {
    var now = DateTime.now();
    var then = DateTime.fromISO(event.start);
    var days = _.floor(_.get(then.diff(now, "days"), "days"));
    return (
      <span
        style={{
          fontWeight: "bold",
          padding: "2px 6px",
          fontSize: "13px",
          lineHeight: "19px",
          background: "rgba(255,255,255,0.7)",
          color: "black",
          ...style
        }}
      >
        IN {days} DAYS
      </span>
    );
  }

  function EventAttributes({
    event,
    locationStyleDesktop,
    locationStyleMobile,
    venueNameStyleMobile
  }) {
    return (
      <>
        <Div
          d={{
            fontWeight: "bold"
          }}
          m={venueNameStyleMobile}
        >
          {event.name}
        </Div>

        {DateTime.fromISO(event.start).toLocaleString(DateTime.DATE_FULL)}
        <br />
        {CustomDurationFormat(event.start, event.end)}
        <Div
          d={locationStyleDesktop}
          m={{
            color: "#707070",
            maxWidth: "75%",
            ...locationStyleMobile
          }}
        >
          <br />
          {event.venueName}
          <br />
          {event.venueAddress}
        </Div>
      </>
    );
  }
  return (
    <div>
      <EventsHeader>Upcoming Events</EventsHeader>

      <HorizontalScroller
        d={{
          marginTop: "45px"
        }}
        m={{
          marginTop: "12px"
        }}
      >
        {upcoming.map((evt) => {
          return (
            <Div
              style={{
                aspectRatio: "0.8",
                background: "#707070 0% 0% no-repeat padding-box",
                position: "relative"
              }}
              className="keen-slider__slide"
              key={evt.id}
              d={{
                width: "calc(25% - 16px)"
              }}
              m={{
                width: "calc(80% - 16px)"
              }}
            >
              <Mobile>
                <CountDownLabel
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px"
                  }}
                  event={evt}
                />
              </Mobile>

              <Div
                style={{
                  background:
                    "transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box",
                  height: "50%",
                  position: "relative",
                  top: "50%",
                  color: "white",
                  padding: "8px",
                  fontSize: "20px",
                  lineHeight: "24px",
                  boxSizing: "border-box"
                }}
                d={{ padding: "12px 16px" }}
                m={{ padding: "8px" }}
                flex="column end start"
              >
                <Desktop>
                  <CountDownLabel
                    event={evt}
                    style={{ marginBottom: "12px" }}
                  />
                </Desktop>
                <EventAttributes
                  event={evt}
                  locationStyleDesktop={{ color: "#FFFFFFB3" }}
                />
              </Div>
            </Div>
          );
        })}
      </HorizontalScroller>

      <EventsHeader
        d={{
          marginTop: "58px"
        }}
        m={{
          marginTop: "24px"
        }}
      >
        Announcements
      </EventsHeader>
      <HorizontalScroller
        d={{
          marginTop: "29px"
        }}
        m={{
          marginTop: "12px"
        }}
      >
        {announcements.map((announcement, i) => {
          return (
            <Div
              className="keen-slider__slide"
              d={{
                width: "calc(25% - 16px)"
              }}
              m={{
                width: "calc(80% - 16px)"
              }}
              key={announcement.id}
            >
              <Div
                style={{
                  background: "black",
                  color: "#FFFFFF",
                  borderRadius: "6px",
                  padding: "8px",
                  height: "auto"
                }}
              >
                {announcement.content}
                <Div
                  style={{
                    color: "#707070",
                    marginTop: "9px"
                  }}
                >
                  {DateTime.fromISO(announcement.date).toLocaleString(
                    DateTime.DATE_MED
                  )}
                </Div>
              </Div>
            </Div>
          );
        })}
      </HorizontalScroller>

      <Div
        d={{
          marginTop: "58px",
          marginBottom: "120px"
        }}
        m={{
          marginTop: "24px",
          marginBottom: "64px"
        }}
      >
        <EventsHeader>All Events</EventsHeader>

        {pastMonths.map(([month, eventsInMonth], i) => {
          return (
            <Div
              key={i}
              d={
                i === 0
                  ? {
                      marginTop: "29px"
                    }
                  : {}
              }
              m={{
                marginTop: i === 0 ? "12px" : "48px"
              }}
            >
              <Header
                className={"top-border"}
                key={month}
                style={{
                  fontSize: "16px",
                  lineHeight: "24px"
                }}
              >
                {DateTime.fromISO(month).toLocaleString({
                  month: "long",
                  year: "numeric"
                })}
              </Header>
              {eventsInMonth.map((evt, i) => {
                return (
                  <Div
                    d={{
                      width: "calc(50% - 16px)",
                      display: "flex",
                      flexDirection: Desktop() ? "row" : "column",
                      paddingTop: i === 0 ? "21px" : "25px",
                      paddingBottom:
                        i === eventsInMonth.length - 1 ? "48px" : "25px",
                      borderBottom:
                        i === eventsInMonth.length - 1
                          ? ""
                          : "2px solid #F5F5F5"
                    }}
                    m={{
                      marginTop: i === 0 ? "48px" : "24px"
                    }}
                    key={evt.id}
                  >
                    <Div
                      style={{
                        aspectRatio: "1.333333",
                        background: "#F6F6F6"
                      }}
                      d={{
                        width: "calc(33.33% - 16px)"
                      }}
                    ></Div>
                    <Div
                      d={{
                        marginLeft: "16px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end"
                      }}
                      m={{
                        marginTop: "10px"
                      }}
                    >
                      <EventAttributes
                        event={evt}
                        venueNameStyleMobile={{ fontWeight: "bold" }}
                        locationStyleMobile={{ color: "black" }}
                      />
                    </Div>
                  </Div>
                );
              })}
            </Div>
          );
        })}
      </Div>
    </div>
  );
}
