
import { StyledLink } from './Elements';
import { Desktop, Mobile } from '../modules/useResponsive';
import Icon from './Icon';

function BigLink({ name, to }) {

  return (
    <StyledLink
      to={to}
      style={{
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center'
      }}
      d={{
        fontSize: '48px',
        height: '72px',
        borderTop: '5px solid black',
        gap: '14.75px'
      }}
      m={{
        fontSize: '28px',
        height: '43px',
        borderTop: '3px solid black',
        gap: '8.6px'
      }}
    >
      {name} <Desktop>
        <Icon ChevronRight style={{
          height: '0.7em',
        }} />
      </Desktop>
      <Mobile>
        <Icon ChevronRight style={{
          height: '0.5em',
        }} />
      </Mobile>
    </StyledLink>
  )
}

export default BigLink;